import {ThemeOptions} from '@mui/material';

const baseTheme: ThemeOptions = {
    typography: {
        button: {
            textTransform: 'none',
        },
        h1: {
            fontSize: 24,
            fontWeight: 600,
        },
        h4: {
            fontSize: 22,
        },
        h5: {
            fontSize: 19,
        },
    },
    components: {
        MuiCard: {
            defaultProps: {
                elevation: 5,
            },
        },
        MuiButton: {
            variants: [
                {
                    style: {
                        padding: `13px 22px`,
                        borderRadius: 25,
                    },
                    props: {
                        size: 'large',
                    },
                },
            ],
        },
    },
};

export default baseTheme;
