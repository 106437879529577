import {ThemeOptions} from '@mui/material';

const g1 = '#7cdc10';
const g2 = '#54c535';

export const g33kTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: g1,
            contrastText: '#ffffff',
        },
        background: {
            default: '#2d2d2d',
            paper: '#808080',
        },
        secondary: {
            main: g2,
            light: '#d63333',
            contrastText: '#1f7557',
        },
        divider: 'rgba(61,246,0,0.22)',
        text: {
            secondary: g2,
            primary: g1,
        },
    },
};
