import {Medium} from '../types/api';
import {StateSetter} from '../types/utils';
import {PaginatedList} from '../types/list';
import axios from 'axios';
import {Violation} from './form';

export function getShortId(id: string): string {
    return `#${id.substring(0, 6)}`;
}

export function getMediumTitle(medium: Medium): string {
    return medium.title ?? getShortId(medium.id);
}

export const isEmail = (email: string): boolean =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.toLowerCase(),
    );

export function getFullName(
    firstName: string | undefined,
    lastName: string | undefined,
): string {
    return `${firstName} ${lastName}`.trim();
}

export function extractIdFromIRI(
    iri: string | null | undefined,
): string | undefined {
    if (!iri) {
        return;
    }

    return iri.replace(/^\/\w+\//, '');
}

export function createIriFromId(entity: string, id: string): string {
    return `/${entity}/${id}`;
}

export function forceArray<T = any>(
    o: Record<string, T> | T[] | undefined,
): T[] {
    if (o === undefined) {
        return [];
    }
    if (!Array.isArray(o)) {
        const arr: T[] = [];

        Object.keys(o).forEach(k => {
            arr.push(o[k]);
        });

        return arr;
    }

    return o;
}

export function humanizeUrl(url: string): string {
    return url.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '');
}

export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createStateSetterProxy<T>(
    handler: T | ((prev: T) => T),
    proxy: (newState: T) => T,
): (prev: T) => T {
    return p => {
        if (typeof handler === 'function') {
            const n = (handler as (prev: T) => T)(p);

            return proxy(n);
        }

        return proxy(handler);
    };
}

export function replaceListItem<T extends {id: string}>(
    setItems: StateSetter<PaginatedList<T> | undefined>,
    itemId: string,
    item: T,
): void {
    setItems(p => {
        return {
            ...p!,
            items: p!.items.map(i => (i.id === itemId ? item : i)),
        };
    });
}

export function escapeI18nextHtml(str: string | null | undefined): string {
    if (!str) {
        return '';
    }

    return str.replace(/<\w+\s*\/?>/gi, '');
}

export function getApiViolations(e: any): string[] | undefined {
    if (axios.isAxiosError(e)) {
        const response = e.response;
        if (response && [400, 422].includes(response.status)) {
            const data = response.data as {
                violations?: Violation[];
                detail?: string;
            };
            if (data.violations) {
                return data.violations.map(v => v.title ?? v.message);
            }

            if (data.detail) {
                return [data.detail];
            }
        }
    }
}
