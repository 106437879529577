import React, {PropsWithChildren} from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import {prefixer} from 'stylis';
import {useTranslation} from 'react-i18next';

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

type Props = PropsWithChildren<{}>;

export default function Rtl({children}: Props) {
    const {i18n} = useTranslation();

    React.useEffect(() => {
        document.body.dir = i18n.dir();
    }, [i18n]);

    if ('rtl' === i18n.dir()) {
        return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
    }

    return children;
}
