import MemoryCache, {CacheClass} from 'memory-cache';

export type Cache = CacheClass<string, any>;

type TaggedCache = {
    cache: Cache;
    tags: string[];
};

const caches: TaggedCache[] = [];

type CacheClearListener = () => void;

const cacheClearListeners: Record<string, CacheClearListener[]> = {};

export function createCache(tags: string[] = []): Cache {
    const cache = new MemoryCache.Cache() as Cache;
    caches.push({
        cache,
        tags,
    });

    return cache;
}

export function clearCaches(): void {
    caches.forEach(c => c.cache.clear());
}

export function clearCachesByTag(tag: string): void {
    caches.filter(c => c.tags.includes(tag)).forEach(c => c.cache.clear());
    if (cacheClearListeners[tag]) {
        cacheClearListeners[tag].forEach(h => h());
    }
}

export function addCacheClearListener(
    tag: string,
    handler: CacheClearListener,
): void {
    if (!cacheClearListeners[tag]) {
        cacheClearListeners[tag] = [];
    }
    cacheClearListeners[tag].push(handler);
}

export function removeCacheClearListener(
    tag: string,
    handler: CacheClearListener,
): void {
    if (!cacheClearListeners[tag]) {
        return;
    }
    cacheClearListeners[tag] = cacheClearListeners[tag].filter(
        h => h !== handler,
    );
}
