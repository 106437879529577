import {useEffect} from 'react';
import {Profile} from '../types/api';

export function useProfileHead(profile?: Profile | undefined) {
    useEffect(() => {
        if (!profile) {
            return;
        }

        const oldTitle = document.title;

        document.title = profile.resolvedTitle;

        const link = document.createElement('link');
        link.href = profile.manifestUrl;
        link.rel = 'manifest';
        const head = document.getElementsByTagName('head')[0];
        const prevManifests = head.querySelectorAll('link[rel="manifest"]');
        prevManifests.forEach(m => head.removeChild(m));
        head.appendChild(link);

        return () => {
            head.removeChild(link);
            prevManifests.forEach(m => head.appendChild(m));
            document.title = oldTitle;
        };
    }, [profile]);
}
