import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

type Props = {} & LinkProps;

export default React.forwardRef<HTMLAnchorElement, Props>(
    ({to, children, ...props}, ref) => {
        if (typeof to === 'string' && to.startsWith('http')) {
            return (
                <a ref={ref} href={to} {...props}>
                    {children}
                </a>
            );
        }
        if (typeof to === 'object' && to.pathname?.startsWith('http')) {
            return (
                <a ref={ref} href={to.pathname} {...props}>
                    {children}
                </a>
            );
        }

        return (
            <Link to={to} ref={ref} {...props}>
                {children}
            </Link>
        );
    },
);
