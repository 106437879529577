import {createTheme} from '@mui/material/styles';
import baseTheme from '../Ui/themes/BaseTheme';
import {mergeDeep} from '../../lib/merge';

export type MuiBackgroundProp = {
    paper?: string;
    menu?: string;
};

export const adminTheme = createTheme(
    mergeDeep({}, baseTheme, {
        palette: {
            mode: 'light',
            primary: {
                main: '#03A9F4',
                contrastText: '#ffffff',
            },
            background: {
                paper: '#fff',
                menu: '#01013C',
            } as MuiBackgroundProp,
            secondary: {
                main: '#01013C',
            },
            error: {
                main: '#E53935',
            },
            warning: {
                main: '#FB8C00',
            },
            success: {
                main: '#81d516',
            },
            divider: 'rgba(133,133,133,0.12)',
            text: {
                secondary: `rgba(0, 0, 0, 0.6)`,
                disabled: '#868686',
                primary: '#000000',
            },
            common: {
                white: '#F2F7FE',
            },
        },
        typography: {
            fontFamily: 'Poppins',
            h1: {
                fontSize: 26,
                fontWeight: 100,
                marginBottom: 16,
            },
            h2: {
                fontSize: 20,
                fontWeight: 600,
                marginBottom: 16,
            },
            h3: {
                fontSize: 17,
                fontWeight: 700,
                marginBottom: 16,
            },
            h5: {
                fontSize: '1.5rem',
                fontWeight: 400,
                marginBottom: 16,
            },
            body2: {
                fontSize: '16px',
                color: 'rgba(0, 0, 0, 0.6)',
            },
        },
        components: {
            MuiCard: {
                defaultProps: {
                    elevation: 1,
                },
            },
            MuiDrawer: {
                defaultProps: {
                    elevation: 1,
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 1,
                },
            },
        },
    }),
);
