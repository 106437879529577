import en from './en/admin.json';
import fr from './fr/admin.json';

import enApi from '@alchemy/api/translations/en.json';
import frApi from '@alchemy/api/translations/fr.json';

import enNav from '@alchemy/navigation/translations/en.json';
import frNav from '@alchemy/navigation/translations/fr.json';

import enAuth from '@alchemy/react-auth/translations/en.json';
import frAuth from '@alchemy/react-auth/translations/fr.json';

const enMerged = {
    ...en,
    lib: {
        ...enApi.lib,
        ...enNav.lib,
        ...enAuth.lib,
    },
};

const frMerged = {
    ...fr,
    lib: {
        ...frApi.lib,
        ...frNav.lib,
        ...frAuth.lib,
    },
};

export {enMerged as en, frMerged as fr};
