import squareStyle from './square';
import roundedStyle from './rounded';
import defaultStyle from './default';

export const styleThemes = {
    default: defaultStyle,
    rounded: roundedStyle,
    square: squareStyle,
};

export type TStyle = keyof typeof styleThemes & string;
