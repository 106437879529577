import {SocialLink} from '../../types/api';
import socials from '../../lib/socials';
import {Icon, IconButton} from '@mui/material';
import {addSourceTracker, TrackingSource} from '../../lib/tracking';

const sx = {
    fontSize: {
        xs: 35,
        md: 40,
    },
};

type Props = {
    isPreview?: boolean;
} & SocialLink;

export default function SocialCard({
    value,
    trackingUrl,
    type,
    isPreview,
}: Props) {
    return (
        <div>
            <IconButton
                title={socials[type].name}
                href={
                    isPreview
                        ? value
                        : trackingUrl
                          ? addSourceTracker(
                                trackingUrl,
                                TrackingSource.Profile,
                            )
                          : value
                }
                target={'_blank'}
                rel={'noopener'}
            >
                <Icon sx={sx} component={socials[type].image} />
            </IconButton>
        </div>
    );
}
