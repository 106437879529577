import React, {FunctionComponent} from 'react';
import AppleImg from '../assets/images/icons/apple.svg?react';
import CalendarImg from '../assets/images/icons/schedule.svg?react';
import GooglePlayImg from '../assets/images/icons/playStore.svg?react';
import LinkImg from '../assets/images/icons/link.svg?react';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import {SvgIcon, SvgIconProps} from '@mui/material';

const CalendarIcon = (props: SvgIconProps) => (
    <SvgIcon component={CalendarImg} inheritViewBox {...props} />
);
const AppleIcon = (props: SvgIconProps) => (
    <SvgIcon component={AppleImg} inheritViewBox {...props} />
);
const GooglePlayIcon = (props: SvgIconProps) => (
    <SvgIcon component={GooglePlayImg} inheritViewBox {...props} />
);
const LinkIcon2 = (props: SvgIconProps) => (
    <SvgIcon component={LinkImg} inheritViewBox {...props} />
);

type LinkIcon = {
    name: string;
    image: FunctionComponent;
    scheme?: string;
};

const linkIcons: Record<string, LinkIcon> = {
    link: {name: 'Link', image: LinkIcon2},
    document: {name: 'Document', image: InsertDriveFileIcon},
    video: {name: 'Video', image: OndemandVideoIcon},
    calendar: {name: 'Calendar', image: CalendarIcon},
    apple: {name: 'Apple', image: AppleIcon},
    googlePlay: {name: 'Google Play', image: GooglePlayIcon},
    phone: {name: 'Phone', image: PhoneIcon, scheme: 'tel:'},
    email: {name: 'Email', image: EmailIcon, scheme: 'mailto:'},
};

export default linkIcons;
