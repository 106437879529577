import {ThemeOptions} from '@mui/material';

const defaultStyle: ThemeOptions = {
    typography: {
        fontFamily: 'Poppins',
    },
    shape: {
        borderRadius: 20,
    },
    shadows: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
};

export default defaultStyle;
