import React, {PropsWithChildren} from 'react';
import {adminTheme} from '../../components/Admin/AdminTheme';
import {GlobalStyles, ThemeProvider} from '@mui/material';

export default function AdminLayout({children}: PropsWithChildren<{}>) {
    return (
        <ThemeProvider theme={adminTheme}>
            <GlobalStyles
                styles={theme => ({
                    body: {
                        backgroundColor: theme.palette.common.white,
                    },
                })}
            />
            {children}
        </ThemeProvider>
    );
}
