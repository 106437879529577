export const locales = [
    'en',
    'fr',
    'ar',
    'de',
    'es',
    'it',
    'zh',
    'fi',
    'id',
    'nl',
    'pt',
    'ro',
    'th',
    'pl',
    'hi',
    'el',
    'af',
    'cs',
    'sv',
    'ja',
];
export const adminLocales = ['en', 'fr'];
export const defaultLocale = 'en';
