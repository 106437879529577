import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardMedia,
    CircularProgress,
    Container,
    Drawer,
    Skeleton,
    Theme,
    Typography,
} from '@mui/material';
import {Profile} from '../../types/api';
import SocialCard from './SocialCard';
import Links from './Links';
import LoadingImg from '../Ui/LoadingImg';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ShareIcon from '@mui/icons-material/Share';
import RepeatIcon from '@mui/icons-material/Repeat';
import LinkIcon from '@mui/icons-material/Link';
import {useTranslation} from 'react-i18next';
import React, {
    PropsWithChildren,
    Suspense,
    useCallback,
    useEffect,
    useState,
} from 'react';
import LogoWrapper from './LogoWrapper';
import {isIOS} from 'react-device-detect';
import {coverHeight, coverWidth} from '../../lib/style';
import {useWindowSize} from '@alchemy/react-hooks/src/useWindowSize';
import defaultCover from '../../assets/images/default-cover-2x.jpg';
import {getBestTranslatedValue} from '../../lib/localeHelper';
import nl2br from 'react-nl2br';
import {lazyWithRetry} from '../../lib/lazyWithRetry';
import {humanizeUrl} from '../../lib/utils';
import {useLocation, useNavigate} from 'react-router-dom';
import {useMatomo} from '@jonkoops/matomo-tracker-react';
import {ErrorBoundary} from '@sentry/react';

const ExchangeForm = lazyWithRetry(
    'ExchangeForm',
    () => import('./ExchangeForm'),
);

type Props = PropsWithChildren<{
    profile: Profile | undefined;
    safeKey: string;
    isPreview?: boolean;
}>;

export default function ProfileView({
    profile,
    safeKey,
    isPreview = false,
    children,
}: Props) {
    const {t} = useTranslation('app');
    const [coverLoaded, setCoverLoaded] = useState(false);
    const {innerWidth} = useWindowSize();
    const navigate = useNavigate();
    const {search} = useLocation();
    const {trackEvent} = useMatomo();

    const sizes = {
        xs: 80,
        md: 100,
        lg: 120,
    };

    const hasShare = Boolean(navigator.share);

    const track = React.useCallback(
        (open: boolean) => {
            trackEvent({
                category: 'interaction',
                action: 'toggle',
                name: 'exchange',
                value: open ? 1 : 0,
            });
        },
        [trackEvent],
    );

    const showExchangeModal = React.useCallback(() => {
        navigate('?exchange');
        track(true);
    }, [track]);
    const closeExchangeModal = React.useCallback(() => {
        navigate('', {replace: true});
        track(false);
    }, [track]);

    const displayExchangeModal = search.includes('exchange');

    useEffect(() => {
        if (profile?.qrCodeUrl) {
            const img = new Image();
            img.src = profile!.qrCodeUrl;
        }
    }, [profile?.qrCodeUrl]);

    const {
        kompany,
        title: titleFallback,
        translations,
        vcfUrl,
        hideCompanyName,
        options,
        firstName: firstNameFallback,
        lastName: lastNameFallback,
        bio: bioFallback,
        locale,
        cover: profileCover,
    } = profile || {};

    const cover = profileCover ?? kompany?.cover;

    const title = getBestTranslatedValue(
        translations ?? {},
        'title',
        titleFallback,
        locale,
    );
    const firstName = getBestTranslatedValue(
        translations ?? {},
        'firstName',
        firstNameFallback,
        locale,
    );
    const lastName = getBestTranslatedValue(
        translations ?? {},
        'lastName',
        lastNameFallback,
        locale,
    );
    const bio = getBestTranslatedValue(
        translations ?? {},
        'bio',
        bioFallback,
        locale,
    );
    const kompanyName = kompany
        ? getBestTranslatedValue(
              kompany.translations ?? {},
              'displayName',
              kompany.name,
              locale,
          )
        : undefined;

    const l = Boolean(profile);

    const fullName =
        (firstName
            ? `${firstName}${lastName ? ` ${lastName}` : ''}`
            : kompany?.name) || '';

    const socials = [
        ...((kompany && kompany.socials) || []),
        ...(profile?.socials || []),
    ];
    const links = [
        ...((kompany && kompany.links) || []),
        ...(profile?.links || []),
    ];

    const navigatorShare = useCallback((title: string): void => {
        if (!hasShare) {
            return;
        }

        navigator
            .share({
                title: `${title} - VKARD.io`,
                url: window.location.href,
            })
            .catch(e => {
                if (!e.toString().includes('AbortError')) {
                    throw e;
                }
            });
    }, []);

    const coverMinHeight = Math.min(
        coverHeight,
        (innerWidth / coverWidth) * coverHeight,
    );

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        padding: 0,
                    }}
                >
                    <Box
                        sx={theme => ({
                            padding: {
                                xs: 0,
                                sm: `0 0 ${theme.spacing(2)}`,
                            },
                        })}
                    >
                        <Card>
                            <CardMedia
                                sx={{
                                    'backgroundColor': '#FFF',
                                    '& .MuiButton-root': {
                                        opacity: 0,
                                        transition: 'opacity 0.2s ease-in-out',
                                    },
                                    '&:hover .MuiButton-root': {
                                        opacity: 1,
                                    },
                                }}
                                style={{position: 'relative'}}
                            >
                                <LogoWrapper
                                    svgSpec={cover?.options}
                                    loaded={coverLoaded}
                                >
                                    {!l && (
                                        <div
                                            style={{
                                                height: coverMinHeight,
                                            }}
                                        >
                                            <Skeleton
                                                sx={{bgcolor: 'grey.100'}}
                                                variant="rectangular"
                                                width={'100%'}
                                                height={'100%'}
                                            />
                                        </div>
                                    )}
                                    {l && (
                                        <LoadingImg
                                            onLoad={() => setCoverLoaded(true)}
                                            loadingMinHeight={coverMinHeight}
                                            src={cover?.url || defaultCover}
                                            alt={fullName}
                                        />
                                    )}
                                </LogoWrapper>
                                {kompany?.siteUrl && (
                                    <Button
                                        color={'secondary'}
                                        variant={'contained'}
                                        sx={theme => ({
                                            position: 'absolute',
                                            textTransform: 'none',
                                            bottom: theme.spacing(1),
                                            right: theme.spacing(1),
                                        })}
                                        href={kompany?.siteUrl}
                                        target={'_blank'}
                                        rel={'noopener noreferrer'}
                                        startIcon={<LinkIcon />}
                                    >
                                        {humanizeUrl(kompany?.siteUrl)}
                                    </Button>
                                )}
                            </CardMedia>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    p: 2,
                                }}
                            >
                                {(!l || profile?.avatar) && (
                                    <Avatar
                                        sx={(theme: Theme) => ({
                                            width: sizes,
                                            height: sizes,
                                            marginTop: {
                                                xs: `-25px`,
                                                md: `-30px`,
                                                lg: `-30px`,
                                            },
                                            marginBottom: theme.spacing(3),
                                            border: `1px solid #FFF`,
                                            boxShadow: theme.shadows[12],
                                        })}
                                    >
                                        {!l && (
                                            <Skeleton
                                                variant="circular"
                                                width={'100%'}
                                                height={'100%'}
                                            />
                                        )}
                                        {l && (
                                            <LoadingImg
                                                src={profile!.avatar!.url}
                                                alt={fullName}
                                            />
                                        )}
                                    </Avatar>
                                )}
                                <div
                                    style={{
                                        flexGrow: 1,
                                        textAlign: 'center',
                                    }}
                                >
                                    {fullName && (
                                        <Typography
                                            gutterBottom
                                            variant="h1"
                                            component="div"
                                        >
                                            {!l && (
                                                <Skeleton
                                                    variant={'text'}
                                                    width={250}
                                                />
                                            )}
                                            {l && fullName}
                                        </Typography>
                                    )}
                                    {(!l || title) && (
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {!l && (
                                                <Skeleton
                                                    variant={'text'}
                                                    width={120}
                                                />
                                            )}
                                            {l && title!}
                                        </Typography>
                                    )}
                                    {(!l || (kompany && !hideCompanyName)) && (
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                        >
                                            {!l && (
                                                <Skeleton
                                                    variant={'text'}
                                                    width={80}
                                                />
                                            )}

                                            {l &&
                                                (kompany!.siteUrl ? (
                                                    <a
                                                        style={{
                                                            color: 'inherit',
                                                            textDecoration:
                                                                'inherit',
                                                        }}
                                                        href={kompany!.siteUrl}
                                                        target={'_blank'}
                                                        rel="noopener noreferrer"
                                                    >
                                                        {kompanyName!}
                                                    </a>
                                                ) : (
                                                    kompanyName!
                                                ))}
                                        </Typography>
                                    )}
                                </div>
                            </Box>

                            <CardActions
                                sx={{
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    gap: 1,
                                }}
                            >
                                {(isPreview || !l || vcfUrl) && (
                                    <Button
                                        href={vcfUrl ?? '#'}
                                        size={'large'}
                                        variant={'contained'}
                                        color={'primary'}
                                        disabled={!l}
                                        startIcon={<CloudDownloadIcon />}
                                    >
                                        {!isIOS
                                            ? t(
                                                  'app:profile.save_contact',
                                                  'Contact info',
                                              )
                                            : t(
                                                  'app:profile.save_contact_ios',
                                                  'Contact info',
                                              )}
                                    </Button>
                                )}{' '}
                                {options?.exchanges && (
                                    <Button
                                        onClick={
                                            !isPreview
                                                ? showExchangeModal
                                                : undefined
                                        }
                                        data-testid={'exchange'}
                                        size={'large'}
                                        variant={'contained'}
                                        color={'secondary'}
                                        disabled={!l}
                                        startIcon={<RepeatIcon />}
                                    >
                                        {t('app:profile.exchange', 'Exchange')}
                                    </Button>
                                )}
                            </CardActions>

                            {(isPreview || hasShare) && (
                                <CardActions
                                    sx={{
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            profile &&
                                                navigatorShare(
                                                    profile.resolvedTitle,
                                                );
                                        }}
                                        size={'small'}
                                        startIcon={<ShareIcon />}
                                    >
                                        {t('app:profile.share', 'Share')}
                                    </Button>
                                </CardActions>
                            )}

                            {bio && (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        mt: 1,
                                        p: 1,
                                        textAlign: 'center',
                                    }}
                                >
                                    {nl2br(bio)}
                                </Typography>
                            )}

                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap',
                                        mt: 2,
                                    }}
                                >
                                    {socials.map((i, index) => (
                                        <div key={index}>
                                            <SocialCard
                                                {...i}
                                                isPreview={isPreview}
                                            />
                                        </div>
                                    ))}
                                </Box>
                                <Links
                                    links={
                                        l
                                            ? links
                                            : [
                                                  {
                                                      '@id': '1',
                                                      'icon': 'link',
                                                      'label': (
                                                          <Skeleton
                                                              variant={'text'}
                                                              width={200}
                                                          />
                                                      ),
                                                  },
                                                  {
                                                      '@id': '2',
                                                      'icon': 'link',
                                                      'label': (
                                                          <Skeleton
                                                              variant={'text'}
                                                              width={150}
                                                          />
                                                      ),
                                                  },
                                              ]
                                    }
                                    locale={locale}
                                    isPreview={isPreview}
                                />
                            </div>
                        </Card>
                        {children}
                    </Box>
                </Container>
                {profile && options?.exchanges && (
                    <Drawer
                        sx={theme => ({
                            zIndex: theme.zIndex.drawer + 2,
                        })}
                        PaperProps={{
                            sx: theme => ({
                                py: {
                                    xs: 2,
                                    sm: 4,
                                },
                                px: {
                                    xs: 2,
                                    sm: 4,
                                },
                                borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                                margin: 'auto',
                                width: '100%',
                                maxWidth: coverWidth,
                            }),
                        }}
                        anchor="bottom"
                        keepMounted={true}
                        open={displayExchangeModal}
                        onClose={closeExchangeModal}
                    >
                        <ErrorBoundary
                            fallback={
                                <>
                                    {t(
                                        'app:common.failed_chunk',
                                        'Failed to load, please reload the page!',
                                    )}
                                </>
                            }
                        >
                            <Suspense
                                fallback={
                                    <div
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                }
                            >
                                <ExchangeForm
                                    fullName={fullName}
                                    onClose={closeExchangeModal}
                                    profileId={profile.id}
                                    profileSafeKey={safeKey!}
                                />
                            </Suspense>
                        </ErrorBoundary>
                    </Drawer>
                )}
            </Box>
        </>
    );
}
