const squareStyle = {
    typography: {
        fontFamily: 'Poppins',
    },
    shape: {
        borderRadius: 0,
    },
};

export default squareStyle;
