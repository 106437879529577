import React from 'react';
import {Button} from '@mui/material';
import NotFound from '../../components/Ui/NotFound';
import {useTranslation} from 'react-i18next';
import AdminLayout from '../layouts/AdminLayout';
import LinkOrExternal from '../../components/Router/LinkOrExternal';
import {config} from '../../config';

export default function MediumDisabled() {
    const {t} = useTranslation();

    return (
        <AdminLayout>
            <NotFound
                title={t('admin:medium_disabled.title', 'Medium is disabled')}
            >
                <Button
                    variant={'contained'}
                    color={'info'}
                    component={LinkOrExternal}
                    to={config.homeUrl}
                >
                    {t('admin:not_found.return_home', 'Return home')}
                </Button>
            </NotFound>
        </AdminLayout>
    );
}
