import React from 'react';
import AdminLayout from '../../pages/layouts/AdminLayout';
import {useTranslation} from 'react-i18next';
import {Box, CircularProgress, Typography} from '@mui/material';

type Props = {};

export default function LoadingDashboard({}: Props) {
    const {t} = useTranslation();
    return (
        <AdminLayout>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        mx: 1,
                        textAlign: 'center',
                    }}
                >
                    <Box
                        sx={{
                            mb: 3,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    <Typography variant={'h1'} color={'primary'}>
                        {t('admin:loading_dashboard', 'Loading Dashboard…')}
                    </Typography>
                </Box>
            </Box>
        </AdminLayout>
    );
}
