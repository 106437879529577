import {defaultTheme} from './default';
import {oceanTheme} from './ocean';
import {g33kTheme} from './g33k';

export const colorThemes = {
    default: defaultTheme,
    ocean: oceanTheme,
    g33k: g33kTheme,
};

export type TColor = keyof typeof colorThemes & string;
