import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import ProfileShow from './pages/Profile/ProfileShow';
import GlobalNotFoundPage from './pages/GlobalNotFoundPage';

type Props = {};

export default function RootProfile({}: Props) {
    const router = createBrowserRouter([
        {
            path: `/:id/:safeKey`,
            Component: ProfileShow,
        },
        {
            path: `/p/:id/:safeKey`,
            Component: ProfileShow,
        },
        {
            path: `*`,
            Component: GlobalNotFoundPage,
        },
    ]);
    return <RouterProvider router={router} />;
}
