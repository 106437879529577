import React from 'react';
import NotFound from '../components/Ui/NotFound';
import {Button, Typography} from '@mui/material';
import AdminLayout from './layouts/AdminLayout';
import {useTranslation} from 'react-i18next';

type Props = {
    error: Error;
};

export default function Error({error}: Props) {
    const {t} = useTranslation();

    console.log('error', error);
    console.error(error.toString());

    return (
        <AdminLayout>
            <NotFound
                title={t(
                    'admin:js_error.title',
                    'Sorry, an error has occurred',
                )}
            >
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            mt: -4,
                            mb: 4,
                            maxWidth: 600,
                        }}
                        variant={'body1'}
                    >
                        {t(
                            'admin:js_error.log_sent',
                            'We will be automatically noticed for this issue and will make effort to fix it fast.',
                        )}
                    </Typography>
                    <Button
                        variant={'contained'}
                        color={'info'}
                        onClick={() => {
                            document.location.reload();
                        }}
                    >
                        {t('admin:js_error.return_home', 'Refresh page')}
                    </Button>
                </div>
            </NotFound>
        </AdminLayout>
    );
}
