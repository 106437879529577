import React, {PropsWithChildren} from 'react';
import {MatomoProvider, createInstance} from '@jonkoops/matomo-tracker-react';
import {config} from '../config';

type Props = PropsWithChildren<{}>;

export default function MatomoAppProvider({children}: Props) {
    const instance = createInstance({
        urlBase: config.matomoUrl,
        siteId: parseInt(config.matomoSiteId),
        heartBeat: {
            active: true,
            seconds: 10,
        },
        configurations: {
            setSecureCookie: true,
            setCookieSameSite: 'Strict',
        },
    });

    return <MatomoProvider value={instance}>{children}</MatomoProvider>;
}
