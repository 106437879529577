import React, {PropsWithChildren} from 'react';

type Props = PropsWithChildren<{
    color: string;
}>;

export default function CircleBorder({color, children}: Props) {
    return (
        <div>
            <div
                style={{
                    backgroundColor: color,
                }}
            >
                {children}
            </div>
            <svg
                viewBox="0 0 376 17"
                fill={color}
                style={{
                    display: 'block',
                    marginTop: -1,
                }}
            >
                <path d="M376,0.579 C376,0.579 275.202,17.435 188.982,16.991 C102.762,16.548 0,0.136 0,0.136 L376,0.579 Z" />
            </svg>
        </div>
    );
}
