import {Box, Typography} from '@mui/material';
import {PropsWithChildren, ReactNode} from 'react';

type Props = PropsWithChildren<{
    title: ReactNode;
}>;

export default function NotFound({title, children}: Props) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '90vh',
            }}
        >
            <Typography variant={'h1'}>{title}</Typography>
            <Box
                sx={{
                    mt: 5,
                }}
            >
                {children}
            </Box>
        </div>
    );
}
