import {ThemeOptions} from '@mui/material';

export const oceanTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#193375',
            contrastText: '#ffffff',
        },
        background: {
            default: '#c5e4ff',
            paper: '#e9f7ff',
        },
        secondary: {
            main: '#56c4c4',
            light: '#935252',
            contrastText: '#ffffff',
            dark: '#73d7dc',
        },
        divider: 'rgba(133,133,133,0.12)',
        text: {
            secondary: '#505050',
            primary: '#385e86',
        },
    },
};
