import {configureClientAuthentication, OAuthClient} from '@alchemy/auth';
import {createHttpClient} from '@alchemy/api';
import {config} from '../config';
import axiosRetry, {isNetworkOrIdempotentRequestError} from 'axios-retry';
import {AxiosError} from 'axios';
import {topLevelDomain} from './urlHelper';

export const hydraDescKey = 'hydra:description';

const oauthBaseUrl = `${config.apiUrl}/oauth2`;
export const oauthHttpClient = createHttpClient(oauthBaseUrl);
export const oauthClient = new OAuthClient({
    clientId: 'vkard_client',
    baseUrl: oauthBaseUrl,
    httpClient: oauthHttpClient,
    cookiesOptions: {
        domain: `.${topLevelDomain}`,
    },
});

const apiClient = createHttpClient(config.apiUrl);

configureClientAuthentication(apiClient, oauthClient);

axiosRetry(apiClient, {
    retryCondition: (error: AxiosError) =>
        isNetworkOrIdempotentRequestError(error) &&
        error.response?.status !== 500,
});

export function isErrorOfCode(e: any, codes: number[]): e is AxiosError {
    return e.isAxiosError && codes.includes(e.response.status);
}

export default apiClient;

export function getApiIdentity(prop: string | {id: string}): string {
    if (typeof prop === 'string') {
        return prop;
    }

    return prop.id;
}
