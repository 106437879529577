import Api from '../../lib/apiClient';
import {CacheClass} from 'memory-cache';
import {createCache} from '../../lib/cache';
import {AxiosRequestConfig} from 'axios';
import {RecursivePartial} from '../../types/list';

export type RequestOptions<T = any> = {
    cache?: CacheClass<string, any>;
    transformResponse?: (r: T) => T;
    params?: any;
    signal?: AbortSignal;
};

type Cache = CacheClass<string, any>;

const caches: Record<string, Cache> = {};

type Entity = object;

export type PutFn<
    Res extends object,
    Input extends object = RecursivePartial<Res>,
> = (id: string, data: Input) => Promise<Res>;
export type PostFn<
    Res extends object,
    Input extends object = RecursivePartial<Res>,
> = (data: Input) => Promise<Res>;

export function getCache(entity: string, tags?: string[]): Cache {
    return caches[entity] || (caches[entity] = createCache(tags || [entity]));
}

export async function post<
    Res extends object,
    Input extends object = RecursivePartial<Res>,
>(
    entity: string,
    data: RecursivePartial<Input>,
    options: RequestOptions<Res> = {},
): Promise<Res> {
    const _r = (await Api.post(entity, data)).data as Res;

    const r = options.transformResponse ? options.transformResponse(_r) : _r;

    if (Object.prototype.hasOwnProperty.call(r, 'id')) {
        putEntityCache(entity, (r as {id: string}).id, r);
    }

    return r;
}

export async function get<T extends Entity>(
    entity: string,
    id: string,
    config?: AxiosRequestConfig,
): Promise<T> {
    const cache = getCache(entity);

    const cached = cache.get(id);
    if (cached) {
        return cached;
    }

    const r = (await Api.get(`/${entity}/${id}`, config)).data as T;

    if (Object.prototype.hasOwnProperty.call(r, 'id')) {
        cache.put((r as {id: string}).id, r);
    }

    return r;
}

export async function changeOwner<T extends Entity>(
    entity: string,
    id: string,
    ownerId: string,
) {
    return (
        await Api.put(`${entity}/${id}/change-owner`, {
            ownerId,
        })
    ).data as T;
}

export async function changeHolder<T extends Entity>(
    entity: string,
    id: string,
    holderId: string | null,
) {
    return (
        await Api.put(`${entity}/${id}/change-holder`, {
            holderId,
        })
    ).data as T;
}

export async function put<
    Res extends Entity,
    Input extends object = RecursivePartial<Res>,
>(
    entity: string,
    id: string,
    data: Input,
    options: RequestOptions<Res> = {},
): Promise<Res> {
    const _r = (await Api.put(`/${entity}/${id}`, data)).data as Res;

    const r = options.transformResponse ? options.transformResponse(_r) : _r;

    putEntityCache(entity, id, r);

    return r;
}

export function putEntityCache(entity: string, id: string, r: any): void {
    clearEntityCache(entity, id);
    getCache(entity).put(id, r);
}

export function clearEntityCache(entity: string, id?: string): void {
    if (id) {
        getCache(entity).del(id);
    }
    getCache(entity + ':l', [entity]).clear();
}

export async function apiDelete<T extends Entity>(
    entity: string,
    id: string,
    options: RequestOptions<T> = {},
): Promise<void> {
    clearEntityCache(entity, id);

    await Api.delete(`/${entity}/${id}`, options);
}

export type ListResponse<T> = {
    items: T[];
    total: number;
    next?: string;
};

type RequestConfig = {
    noCache?: boolean;
};

export async function list<T extends Entity>(
    entity: string,
    options: RequestOptions = {},
    config: RequestConfig = {},
): Promise<ListResponse<T>> {
    const cacheKey = `l:${JSON.stringify(options.params)}`;
    const cached = getCache(entity + ':l', [entity]).get(cacheKey);
    if (cached && !config.noCache) {
        return cached;
    }

    const r = (
        await Api.get(`/${entity}`, {
            params: options.params,
            signal: options.signal,
        })
    ).data;

    const result = createListResponse<T>(r);

    if (!config.noCache) {
        getCache(entity + ':l', [entity]).put(cacheKey, result);
    }

    return result;
}

export function createListResponse<T>(r: any): ListResponse<T> {
    return {
        items: r['hydra:member'],
        total: r['hydra:totalItems'],
        next: r['hydra:view'] ? r['hydra:view']['hydra:next'] : undefined,
    };
}

export async function customList<T>(
    path: string,
    cacheTags?: string[],
    options: RequestOptions = {},
): Promise<T> {
    const cache = getCache(`${path}:cl`, cacheTags);
    const cacheKey = `l:${JSON.stringify(options?.params)}`;
    const cached = cache.get(cacheKey);
    if (cached) {
        return cached;
    }

    const r = (await Api.get(`/${path}`, options)).data as T;

    cache.put(cacheKey, r);

    return r;
}
