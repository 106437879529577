import {ThemeOptions} from '@mui/material';

export const defaultTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#1B1232',
        },
        background: {
            default: '#FFF',
            paper: '#FFF',
        },
        secondary: {
            main: '#EBECF0',
            light: '#d63333',
            contrastText: '#1B1232',
            dark: '#dadbe0',
        },
        divider: 'rgba(133,133,133,0.12)',
        text: {
            secondary: '#505050',
            primary: '#02024c',
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    background: `linear-gradient(130deg, #2095C4, #10679D)`,
                },
            },
        },
    },
};
