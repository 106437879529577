import {Profile, User} from '../../types/api';
import {
    apiDelete,
    changeHolder,
    changeOwner,
    clearEntityCache,
    createListResponse,
    get,
    list,
    ListResponse,
    post,
    PostFn,
    put,
    PutFn,
    RequestOptions,
} from './CRUD';
import {AxiosRequestConfig} from 'axios';
import Api from '../../lib/apiClient';
import {logError} from '../../sentry';
import {clearCachesByTag} from '../../lib/cache';
import {mediumEntity} from './MediumApi';
import {ImageCrop} from '../../components/Admin/Form/Picture/PictureCropper';
import {Area} from 'react-easy-crop';
import {deleteEntityFile, uploadEntityFile} from './UploadApi';
import {RecursivePartial} from '../../types/list';
import {BatchEditInput} from './KompanyApi';
import {RowData, SheetFilters} from '../../components/Sheet/types';
import {getThrottle} from '../../lib/requestListThrottle';

export const profileEntity = 'profiles';

export const listProfiles = (
    options?: RequestOptions,
    config: {
        noCache?: boolean;
    } = {},
) => list<Profile>(profileEntity, options, config);

export const listOrganizationProfiles = (
    organizationId: string,
    options?: RequestOptions,
    config: {
        noCache?: boolean;
    } = {},
) =>
    listProfiles(
        {
            ...options,
            params: {
                ...(options?.params ?? {}),
                organizationId: organizationId,
            },
        },
        config,
    );

export const listProfileHolders = (
    options?: RequestOptions,
    config: {
        noCache?: boolean;
    } = {},
) => list<Profile>(`${profileEntity}/holders`, options, config);

export const getProfile = (
    id: string,
    safeKey?: string,
    config?: AxiosRequestConfig,
) =>
    get<Profile>(profileEntity, id, {
        ...config,
        params: {
            ...(config?.params ?? {}),
            safeKey,
        },
    });

export const getProfileThrottle = async (id: string): Promise<Profile> => {
    return await getThrottle(profileEntity, id, async ids => {
        return (
            await listProfiles({
                params: {
                    ids,
                },
            })
        ).items;
    });
};

export const putProfile: PutFn<
    Profile,
    RecursivePartial<ProfileWithMediumInput>
> = async (id, data) => {
    delete data.safeKey;
    delete data.vcfUrl;
    const res = await put<Profile, RecursivePartial<ProfileWithMediumInput>>(
        profileEntity,
        id,
        data,
    );
    clearCachesByTag(mediumEntity);

    return res;
};

export type ProfileWithMediumInput = {
    withMedium?: boolean;
    medium?:
        | {
              printContext?: number;
              productCode?: string;
              order?: string | null;
              assigned?: boolean;
              activated?: boolean;
              owner?: User;
          }
        | undefined;
} & Profile;

export const postProfile: PostFn<
    Profile,
    RecursivePartial<ProfileWithMediumInput>
> = async data => post(profileEntity, data);

export const changeProfileOwner = async (id: string, userId: string) => {
    const res = await changeOwner<Profile>(profileEntity, id, userId);
    clearEntityCache(profileEntity, id);
    clearCachesByTag(mediumEntity);

    return res;
};

export const changeProfileHolder = async (
    id: string,
    userId: string | null,
) => {
    const res = await changeHolder<Profile>(profileEntity, id, userId);
    clearEntityCache(profileEntity, id);
    clearCachesByTag(mediumEntity);

    return res;
};

export const deleteProfile = async (id: string) => {
    await apiDelete(profileEntity, id);
    clearCachesByTag(mediumEntity);
};

export async function logProfileView(id: string): Promise<void> {
    try {
        await Api.post(`/profile-views/${id}/log`, undefined, {
            'axios-retry': {
                retries: 3,
            },
        } as AxiosRequestConfig<undefined>);
    } catch (e) {
        logError(e, {
            tags: {
                section: 'profile-log',
            },
        });
    }
}

export async function uploadProfileImage(
    id: string,
    file: ImageCrop,
    type: ImageType,
    area?: Area,
): Promise<Profile> {
    return await uploadEntityFile<Profile>(profileEntity, type, id, file, area);
}

export enum ImageType {
    Avatar = 'avatar',
    Logo = 'logo',
    Cover = 'cover',
    Background = 'background',
    AppClipHeader = 'app-clip-header',
}

export const deleteProfileImage = async (
    id: string,
    type: ImageType,
): Promise<Profile> => {
    const res = await deleteEntityFile<Profile>(profileEntity, type, id);
    clearCachesByTag(mediumEntity);

    return res;
};

export const getKompanyProfiles = async (id: string) => {
    return await listProfiles(
        {
            params: {
                kompany: id,
            },
        },
        {
            noCache: true,
        },
    );
};

export type TransferProfileInput = {
    target: string;
    applyToProfiles: boolean;
    applyToMediums: boolean;
};

export const transferProfile = async (
    id: string,
    data: TransferProfileInput,
) => {
    const result = await post<Profile, TransferProfileInput>(
        `${profileEntity}/${id}/transfer`,
        data,
    );

    clearEntityCache(profileEntity, id);

    return result;
};

export async function putProfileBatchEdit(data: BatchEditInput): Promise<
    WithProfileProvision<{
        data: RowData[];
    }>
> {
    const r = await Api.post(`${profileEntity}/batch-edit`, data);
    clearEntityCache(mediumEntity);
    clearEntityCache(profileEntity);

    return r.data;
}

export type WithProfileProvision<T> = T & {
    profileProvision: number | undefined;
};

export async function getProfileBatchEdit(
    organizationId: string,
    filters: SheetFilters,
): Promise<WithProfileProvision<ListResponse<RowData>>> {
    const r = (
        await Api.get(`${profileEntity}/batch-edit`, {
            params: {
                ...filters,
                organizationId,
            },
        })
    ).data;

    return {
        ...createListResponse<RowData>(r),
        profileProvision: r.profileProvision,
    };
}

export type ProfileLeadStatusResponse = {
    totalProfiles: number;
    totalEnabled: number;
};
