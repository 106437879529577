import LogoImg from '../../assets/images/vkardio-logo.svg?react';
import {Box, Link} from '@mui/material';
import {config} from '../../config';
import {useTranslation} from 'react-i18next';
import {getUtmParams} from '../../lib/tracking';

type Props = {
    profileId: string;
};

export default function PoweredBy({profileId}: Props) {
    const {t} = useTranslation('app');

    return (
        <Box
            sx={theme => ({
                margin: theme.spacing(4),
                whiteSpace: 'nowrap',
                textAlign: 'center',
                fontSize: 14,
                a: {
                    textDecoration: 'none',
                    svg: {
                        maxHeight: 14,
                        verticalAlign: 'middle',
                        marginLeft: theme.spacing(1),
                    },
                },
            })}
        >
            <Link
                href={`${config.homeUrl}?${getUtmParams({
                    medium: 'profile',
                    source: 'powered-by',
                    term: profileId,
                })}`}
                target={'_blank'}
                title={t('app:get_your_vkard', 'Get your VKARD!')}
            >
                <LogoImg />
            </Link>
        </Box>
    );
}
