import {useEffect, useState} from 'react';

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>;

    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;

    prompt(): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

export function useA2HS() {
    const [event, setEvent] = useState<BeforeInstallPromptEvent>();
    const [accepted, setAccepted] = useState(false);

    useEffect(() => {
        const listener = (e: BeforeInstallPromptEvent) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            setEvent(e);
        };
        window.addEventListener('beforeinstallprompt', listener);

        return () => {
            window.removeEventListener('beforeinstallprompt', listener);
        };
    }, []);

    return {
        prompt: () => {
            if (event) {
                event.prompt();

                event.userChoice.then(choiceResult => {
                    if (choiceResult.outcome === 'accepted') {
                        setAccepted(true);
                    }
                    setEvent(undefined);
                });
            }
        },
        ready: Boolean(event),
        accepted,
    };
}
