import {useTranslation} from 'react-i18next';
import AdminLayout from './layouts/AdminLayout';
import {Button} from '@mui/material';
import NotFound from '../components/Ui/NotFound';
import {config} from '../config';

export default function GlobalNotFoundPage() {
    const {t} = useTranslation();

    return (
        <AdminLayout>
            <NotFound title={t('app:not_found.title', 'Page not found')}>
                <Button
                    variant={'contained'}
                    color={'info'}
                    href={config.homeUrl}
                >
                    {t('app:not_found.return_home', 'Return home')}
                </Button>
            </NotFound>
        </AdminLayout>
    );
}
