type UtmMedium = 'dashboard' | 'profile';
type UtmSource = 'powered-by' | 'h-menu';
type UtmTerm = string;

export function getUtmParams({
    medium,
    source,
    term,
}: {
    medium: UtmMedium;
    source: UtmSource;
    term?: UtmTerm;
}) {
    let p = `utm_medium=${medium}&utm_source=${encodeURIComponent(source)}`;

    if (term) {
        p += `&utm_term=${encodeURIComponent(term)}`;
    }

    return p;
}

export enum TrackingSource {
    Profile = 'profile',
    Signature = 'signature',
    PreviewTab = 'preview-tab',
}

export function addSourceTracker(url: string, source: TrackingSource): string {
    const u = new URL(url);
    u.searchParams.set('source', source);

    return u.toString();
}
