import React from 'react';
import BehanceImg from '../assets/images/socials/behance.svg?react';
import DiscordImg from '../assets/images/socials/discord.svg?react';
import DribbleImg from '../assets/images/socials/dribble.svg?react';
import FacebookImg from '../assets/images/socials/facebook.svg?react';
import GooglemybusinessImg from '../assets/images/socials/googlemybusiness.svg?react';
import InstagramImg from '../assets/images/socials/instagram.svg?react';
import LineImg from '../assets/images/socials/line.svg?react';
import LinkedinImg from '../assets/images/socials/linkedin.svg?react';
import MessengerImg from '../assets/images/socials/messenger.svg?react';
import PinterestImg from '../assets/images/socials/pinterest.svg?react';
import QqImg from '../assets/images/socials/qq.svg?react';
import RedditImg from '../assets/images/socials/reddit.svg?react';
import SkypeImg from '../assets/images/socials/skype.svg?react';
import SlackImg from '../assets/images/socials/slack.svg?react';
import SnapchatImg from '../assets/images/socials/snapchat.svg?react';
import SpotifyImg from '../assets/images/socials/spotify.svg?react';
import TeamsImg from '../assets/images/socials/teams.svg?react';
import TelegramImg from '../assets/images/socials/telegram.svg?react';
import TiktokImg from '../assets/images/socials/tiktok.svg?react';
import TumblrImg from '../assets/images/socials/tumblr.svg?react';
import TwitchImg from '../assets/images/socials/twitch.svg?react';
import XImg from '../assets/images/socials/x.svg?react';
import ViberImg from '../assets/images/socials/viber.svg?react';
import VimeoImg from '../assets/images/socials/vimeo.svg?react';
import WechatImg from '../assets/images/socials/wechat.svg?react';
import WeiboImg from '../assets/images/socials/weibo.svg?react';
import WhatsappImg from '../assets/images/socials/whatsapp.svg?react';
import XiaohongshuImg from '../assets/images/socials/xiaohongshu.svg?react';
import YoutubeImg from '../assets/images/socials/youtube.svg?react';
import ZoomImg from '../assets/images/socials/zoom.svg?react';

const socials: Record<
    string,
    {
        image: React.FC<React.SVGProps<SVGSVGElement>>;
        name: string;
    }
> = {
    behance: {image: BehanceImg, name: 'Behance'},
    discord: {image: DiscordImg, name: 'Discord'},
    dribble: {image: DribbleImg, name: 'Dribble'},
    facebook: {image: FacebookImg, name: 'Facebook'},
    googleMyBusiness: {image: GooglemybusinessImg, name: 'Google My Business'},
    instagram: {image: InstagramImg, name: 'Instagram'},
    lineImg: {image: LineImg, name: 'Line'},
    linkedin: {image: LinkedinImg, name: 'LinkedIn'},
    messenger: {image: MessengerImg, name: 'Messenger'},
    pinterest: {image: PinterestImg, name: 'Pinterest'},
    qq: {image: QqImg, name: 'QQ'},
    reddit: {image: RedditImg, name: 'Reddit'},
    skype: {image: SkypeImg, name: 'Skype'},
    slack: {image: SlackImg, name: 'Slack'},
    snapchat: {image: SnapchatImg, name: 'Snapchat'},
    spotify: {image: SpotifyImg, name: 'Spotify'},
    teams: {image: TeamsImg, name: 'Microsoft Teams'},
    telegram: {image: TelegramImg, name: 'Telegram'},
    tiktok: {image: TiktokImg, name: 'TikTok'},
    tumblr: {image: TumblrImg, name: 'Tumblr'},
    twitch: {image: TwitchImg, name: 'Twitch'},
    twitter: {image: XImg, name: 'X (ex-Twitter)'},
    viber: {image: ViberImg, name: 'Viber'},
    vimeo: {image: VimeoImg, name: 'Vimeo'},
    wechat: {image: WechatImg, name: 'WeChat'},
    weibo: {image: WeiboImg, name: 'Weibo'},
    whatsapp: {image: WhatsappImg, name: 'WhatsApp'},
    xiaohongshu: {image: XiaohongshuImg, name: 'Xiaohongshu'},
    youtube: {image: YoutubeImg, name: 'YouTube'},
    zoom: {image: ZoomImg, name: 'Zoom'},
};

export default socials;
