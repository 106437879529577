const promises: Record<string, Promise<any>> = {};

export function runOrGetPromise<T>(
    key: string,
    callback: () => Promise<T>,
): Promise<T> {
    if (Object.prototype.hasOwnProperty.call(promises, key)) {
        return promises[key];
    }

    return (promises[key] = callback().finally(() => {
        delete promises[key];
    }));
}
